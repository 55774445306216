import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Col, Container, View} from '@amzn/stencil-react-components/layout';
import {useTranslation} from "react-i18next";
import Footer from 'src/components/common/Footer';
import {UserType} from 'src/constants/usertype';
import Corp from 'src/images/icon_corp2.svg';
import UserSelectionTile from './usertypes/UserSelectionTile';
import TranslationKeys from 'src/constants/translation-keys';
import LoginForm from './LoginForm';
import Logo from './common/Logo';
import {
    showCorpModal,
    showCsgoModal,
    showJobseekerModal,
    showPbModal,
    showSruModal
} from 'src/constants/feature-gates';
import AssetsLogo from 'src/images/logo2.svg';
import {redirect} from 'react-router';
import ScreenNames from 'src/constants/screen-names';
import useRecordScreenVisit from 'src/hooks/useRecordScreenVisit';
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {clearRequestSource, setRequestSource} from "src/redux/request-source-slice";
import DaIcon from "src/images/icon_da.svg";
import CsgoIcon from "src/images/icon_csgo2.svg";
import SruIcon from "src/images/icon_sru.svg";
import PreboarderIcon from "src/images/icon_preboarder2.svg";
import ParameterNames from "src/constants/parameter-names";
import GetHelpFlyout from "src/components/common/GetHelpFlyout";
import {recordEvent} from "src/analytics/amplify";
import defaultTheme from '@amzn/stencil-react-theme-default';
import { isMobile } from 'react-device-detect';

const DeconsolidatedLoginScreenV2 = () => {
    const {t} = useTranslation();

    useLayoutEffect(() => {
        document.title = 'Amazon A to Z Login'
    }, []);
    useEffect(() => {
        document.body.classList.add(
            'login-page',
            'use-style-guide'
        );
    }, []);

    useRecordScreenVisit(ScreenNames.DECONSOLIDATED_LOGIN);

    const dispatch = useAppDispatch();
    const source: string = useAppSelector(state => state.requestSource.value);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sourceParam = params.get('source');
        if (sourceParam) {
            dispatch(setRequestSource(sourceParam));
        }
    }, []);

    const [daHref, setDaHref] = useState<string>('');
    useEffect(() => {
        const newHref = (source === ParameterNames.REQUEST_SOURCE_AUTHENTICATION_CONTROLLER) ?
            "{{DaAuthenticationControllerUrl}}" : "{{DaEsspUrl}}";
        setDaHref(newHref);
        console.log('myQueryParam:', source);
        console.log('Updated href:', newHref);
    }, []);

    const handleDaOnClick = () => {
        dispatch(clearRequestSource());
        redirect(daHref);
    }

    function handleFlyoutClick(screenName: string, flyoutType: string) {
        recordEvent({
            name: `${screenName}.${flyoutType}.Click`,
            immediate: true
        });
    }

    return (
        <>
            <div className='background-login-page'/>
            <div className='centered-content' style={{zIndex: 0}}>
                <div data-testid='deconsolidated-login-content'
                     style={{
                         border: `1px solid ${defaultTheme.color.neutral20}`,
                         borderRadius: '8px',
                         backgroundColor: 'white',
                         padding: '40px 5% 40px 5%',
                         marginBottom: '24px'
                     }}>
                    <Logo className='txt-cntr' src={AssetsLogo}/>
                    <LoginForm screenName={ScreenNames.DECONSOLIDATED_LOGIN}/>

                    <div className="horizontal-line-separator">
                        <span>{t(TranslationKeys.UI.LOGIN_FORMS.LOGIN_SEPARATOR)}</span>
                    </div>

                    <Container
                        style={{
                            borderRadius: '8px',
                            border: `1px solid ${defaultTheme.color.neutral20}`,
                            overflow: 'hidden',
                        }}
                    >
                        <Col>
                            {showCorpModal() &&
                                <View style={{borderBottom: `1px solid ${defaultTheme.color.neutral20}`}}>
                                    <UserSelectionTile
                                        userType={UserType.CORP}
                                        screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                                        header={TranslationKeys.UI.USERTYPES.CORPORATE_HEADER2}
                                        description={TranslationKeys.UI.USERTYPES.CORPORATE_DESCRIPTION}
                                        iconSrc={Corp}
                                        href={isMobile ? "/forced-aea" : "{{CorpEsspUrl}}"}
                                    />
                                </View>
                            }
                            <View>
                                <UserSelectionTile
                                    userType={UserType.DA}
                                    screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                                    header={TranslationKeys.UI.USERTYPES.DA_HEADER2}
                                    description={TranslationKeys.UI.USERTYPES.DA_DESCRIPTION}
                                    iconSrc={DaIcon}
                                    onClick={handleDaOnClick}
                                    href={daHref}/>
                            </View>
                            {showPbModal() &&
                                <View style={{borderTop: `1px solid ${defaultTheme.color.neutral20}`}}>
                                    <UserSelectionTile
                                        userType={UserType.PREBOARDER}
                                        screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                                        header={TranslationKeys.UI.USERTYPES.PREBOARDER_HEADER2}
                                        description={TranslationKeys.UI.USERTYPES.PREBOARDER_DESCRIPTION}
                                        iconSrc={PreboarderIcon}
                                        onClick={() => redirect("{{PbEsspUrl}}")}
                                        href={"{{PbEsspUrl}}"}
                                        hidden={!showPbModal}
                                    />
                                </View>
                            }
                            {showCsgoModal() &&
                                <View style={{borderTop: `1px solid ${defaultTheme.color.neutral20}`}}>
                                    <UserSelectionTile
                                        userType={UserType.CSGO}
                                        screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                                        header={TranslationKeys.UI.USERTYPES.CSGO_HEADER2}
                                        description={TranslationKeys.UI.USERTYPES.CSGO_DESCRIPTION}
                                        iconSrc={CsgoIcon}
                                        onClick={() => redirect("/csgo-region")}
                                        href={"/csgo-region"}
                                        hidden={!showCsgoModal}
                                    />
                                </View>
                            }
                            {showSruModal() &&
                                <View style={{borderTop: `1px solid ${defaultTheme.color.neutral20}`}}>
                                    <UserSelectionTile
                                        userType={UserType.SRU}
                                        screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                                        header={TranslationKeys.UI.USERTYPES.SRU_HEADER2}
                                        description={TranslationKeys.UI.USERTYPES.SRU_DESCRIPTION}
                                        iconSrc={SruIcon}
                                        href={"{{SruAuthenticationControllerUrl}}"}
                                        onClick={() => redirect("{{SruAuthenticationControllerUrl}}")}
                                        hidden={!showSruModal}
                                    />
                                </View>
                            }
                            {showJobseekerModal() &&
                                <View style={{borderTop: `1px solid ${defaultTheme.color.neutral20}`}}>
                                    <UserSelectionTile
                                        userType={UserType.JOBSEEKER}
                                        screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                                        header={TranslationKeys.UI.USERTYPES.JOBSEEKER_HEADER}
                                        description={TranslationKeys.UI.USERTYPES.JOBSEEKER_DESCRIPTION}
                                        // TODO update this when we get the Jobseeker Icon
                                        iconSrc={PreboarderIcon}
                                        onClick={() => redirect("{{JobseekerAuthenticationControllerUrl}}")}
                                        href={"{{JobseekerAuthenticationControllerUrl}}"}
                                        hidden={!showJobseekerModal}
                                    />
                                </View>
                            }
                        </Col>
                    </Container>
                    <View onClick={() => handleFlyoutClick(ScreenNames.USERTYPE_SELECTION, 'NotSure')}
                          style={{ paddingTop: '16px' }}>
                        <GetHelpFlyout buttonText={t(TranslationKeys.UI.BUTTONS.NOT_SURE)}
                                       hideButtonIcon={true}/>
                    </View>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default DeconsolidatedLoginScreenV2;
