/**
 * Utilized so the Katal replacements can operate correctly - we have to put the value into a string so that Typescript
 * compiles correctly, then we can convert to boolean. toLowerCase ensures the comparison between the strings does not
 * occur until runtime.
 */
export const showJobseekerModal = (): boolean => '{{ShouldRenderJobseeker}}'.toLowerCase() === 'true';

export const showPbModal = (): boolean => '{{ShouldRenderPb}}'.toLowerCase() === 'true';

export const showDspaModal = (): boolean => '{{ShouldRenderDspa}}'.toLowerCase() === 'true';

export const showCsgoModal = (): boolean => '{{ShouldRenderCsgo}}'.toLowerCase() === 'true';

export const showSruModal = (): boolean => '{{ShouldRenderSru}}'.toLowerCase() === 'true';

export const showCorpModal = (): boolean => '{{ShouldRenderCorp}}'.toLowerCase() === 'true';

export const showPrivacyPolicyIngress = (): boolean => '{{ShowAlumniPrivacyPolicy}}'.toLowerCase() === 'true';

export const showNewActivationFlow = (): boolean => '{{ShowNewActivationFlow}}'.toLowerCase() === 'true';

export const showDeconsolidatedLogin = (): boolean => '{{ShowDeconsolidatedLogin}}'.toLowerCase() === 'true';

export const isPhaseTwo = (): boolean => showCsgoModal() || showSruModal();

export const isPhaseThree = (): boolean => showPbModal();
