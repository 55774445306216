type helpMapType = {
    [key: string]: any
}

type UIType = {
    [key: string]: any
}

export default class TranslationKeys {
    public static readonly HELP : helpMapType = {
        HELP_CONTENT: 'help_content',
        TITLE_HEADER: 'help_title_heading',
        LOGIN_AMAZON_ALIAS_HEADER: 'help_login_amazon_alias_heading2',
        LOGIN_AMAZON_ALIAS_ASSOCIATE_KEY: 'help_login_amazon_alias_associate_key',
        LOGIN_AMAZON_ALIAS_ASSOCIATE_VALUE: 'help_login_amazon_alias_associate_value',
        LOGIN_AMAZON_ALIAS_SALARIED_EMPLOYEE_KEY: 'help_login_amazon_alias_salaried_employee_key',
        LOGIN_AMAZON_ALIAS_SALARIED_EMPLOYEE_VALUE: 'help_login_amazon_alias_salaried_employee_value',
        LOGIN_AMAZON_ALIAS_NOTE: 'help_login_amazon_alias_note',
        LOGIN_AMAZON_SUSPENDED_EMPLOYEES: 'help_login_amazon_suspended_employees',

        LOGIN_NOT_EMPLOYEE_HEADER: 'help_not_employee_heading2',
        LOGIN_DA_HEADER: 'help_login_da_heading',
        LOGIN_DA_DESCRIPTION: 'help_login_dsp_description2',
        LOGIN_DSP_HEADER: 'help_login_dsp_heading2',
        LOGIN_DSP_DESCRIPTION: 'help_login_da_description',
        LOGIN_CSGO_HEADER: 'help_login_csgo_heading2',
        LOGIN_CSGO_DESCRIPTION: 'help_login_csgo_description2',
        LOGIN_SRU_HEADER: 'help_login_sru_heading2',
        LOGIN_SRU_DESCRIPTION: 'help_login_sru_description3',
        LOGIN_ALUMNI_HEADER: 'help_login_alumni_heading',
        LOGIN_ALUMNI_DESCRIPTION: 'help_login_alumni_description2',
        LOGIN_PREBOARDER_HEADER: 'help_login_preboarder_heading2',
        LOGIN_PREBOARDER_DESCRIPTION: 'help_login_preboarder_description2',
        LOGIN_PROSPECT_HEADER: 'help_login_prospect_heading',
        LOGIN_PROSPECT_DESCRIPTION: 'help_login_prospect_description',

        FAQ_TITLE_HEADER: 'faq_title',
        FAQ_DOES_NOT_APPLY: 'faq_does_not_apply',
        FAQ_ENTER_LOGIN_HEADER: 'faq_title_enter_login',
        FAQ_ENTER_LOGIN_QUESTION: 'faq_question_enter_login',
        FAQ_ENTER_LOGIN_ANSWER: 'faq_answer_enter_login',
        FAQ_ENTER_LOGIN_ALUMNI_ANSWER: 'faq_answer_enter_login_alumni',
        FAQ_ENTER_LOGIN_ALUMNI_ANSWER2: 'faq_answer_enter_login_alumni2',
        FAQ_ENTER_LOGIN_ALUMNI_ANSWER3: 'faq_answer_enter_login_alumni3',

        FAQ_SELECT_MOBILE_PHONE_EMAIL_HEADER: 'faq_title_select_mobile_phone_email',
        FAQ_SELECT_MOBILE_PHONE_EMAIL_QUESTION: 'faq_question_select_mobile_phone_email_1',
        FAQ_SELECT_MOBILE_PHONE_EMAIL_ANSWER: 'faq_answer_select_mobile_phone_email_1',

        FAQ_VERIFICATION_CODE_HEADER: 'faq_title_verification_code',
        FAQ_VERIFICATION_CODE_QUESTION_1: 'faq_question_verification_code_1',
        FAQ_VERIFICATION_CODE_ANSWER_1A: 'faq_answer_verification_code_1a',
        FAQ_VERIFICATION_CODE_ANSWER_1B: 'faq_answer_verification_code_1b',
        FAQ_VERIFICATION_CODE_ANSWER_1C: 'faq_answer_verification_code_1c',
        FAQ_VERIFICATION_CODE_ANSWER_1D: 'faq_answer_verification_code_1d',
        FAQ_VERIFICATION_CODE_ANSWER_1E: 'faq_answer_verification_code_1e_2',
        FAQ_VERIFICATION_CODE_QUESTION_2: 'faq_question_verification_code_2',
        FAQ_VERIFICATION_CODE_ANSWER_2: 'faq_answer_verification_code_2',
        FAQ_VERIFICATION_CODE_ANSWER_2_ALUMNI: 'faq_answer_verification_code_2_alumni',
        FAQ_VERIFICATION_CODE_ANSWER_2_ALUMNI_SMS: 'faq_answer_verification_code_2_alumni_sms',
        FAQ_VERIFICATION_CODE_ANSWER_2_ALUMNI_EMAIL: 'faq_answer_verification_code_2_alumni_email',
        FAQ_VERIFICATION_CODE_ANSWER_2_ALUMNI_RESET_PASSWORD: 'faq_answer_verification_code_2_alumni_reset_password',
        FAQ_VERIFICATION_CODE_QUESTION_3: 'faq_question_verification_code_3',
        FAQ_VERIFICATION_CODE_ANSWER_3: 'faq_answer_verification_code_3',
        FAQ_VERIFICATION_CODE_QUESTION_4: 'faq_question_verification_code_4',
        FAQ_VERIFICATION_CODE_ANSWER_4: 'faq_answer_verification_code_4_1',

        FAQ_PASSWORD_HEADER: 'faq_title_password',
        FAQ_PASSWORD_QUESTION_1: 'faq_question_password_1',
        FAQ_PASSWORD_ANSWER_1: 'faq_answer_password_1',
        FAQ_PASSWORD_QUESTION_2: 'faq_question_password_2',
        FAQ_PASSWORD_ANSWER_2: 'faq_answer_password_2',
        FAQ_PASSWORD_QUESTION_3: 'faq_question_password_3',
        FAQ_PASSWORD_ANSWER_3: 'faq_answer_password_3',
        FAQ_PASSWORD_QUESTION_4: 'faq_question_password_4',
        FAQ_PASSWORD_ANSWER_4: 'faq_answer_password_4',
        FAQ_PASSWORD_QUESTION_5: 'faq_question_password_5',
        FAQ_PASSWORD_ANSWER_5: 'faq_answer_password_5',
        FAQ_PASSWORD_QUESTION_6: 'faq_question_password_6',
        FAQ_PASSWORD_ANSWER_6: 'faq_answer_password_6',
        FAQ_PASSWORD_QUESTION_7: 'faq_question_password_7',
        FAQ_PASSWORD_ANSWER_7: 'faq_answer_password_7_2',
        FAQ_PASSWORD_QUESTION_8: 'faq_question_password_8',
        FAQ_PASSWORD_ANSWER_8: 'faq_answer_password_8',

        FAQ_AMAZON_JOBS_HEADER: 'faq_title_amazon_jobs',
        FAQ_AMAZON_JOBS_QUESTION: 'faq_question_amazon_jobs',
        FAQ_AMAZON_JOBS_ANSWER_INTERNAL: 'faq_answer_amazon_jobs_internal',
        FAQ_AMAZON_JOBS_ANSWER_EXTERNAL: 'faq_answer_amazon_jobs_external',
        FAQ_AMAZON_JOBS_ANSWER_ALUMNI: 'faq_answer_amazon_jobs_alumni',

        NEED_MORE_HELP_HEADER: 'need_more_help_heading',
        NEED_MORE_HELP_DESCRIPTION: 'need_more_help_description2',

        FAQ_ERC_ANSWER1_US: 'faq_answer_erc_contact_UnitedStates',
        FAQ_ERC_ANSWER1_US_CONTACT: 'faq_answer_erc_contact_UnitedStatesContact',
        FAQ_ERC_ANSWER1_UK: 'faq_answer_erc_contact_UnitedKingdom',
        FAQ_ERC_ANSWER1_UK_CONTACT: 'faq_answer_erc_contact_UnitedKingdomContact',
        FAQ_ERC_ANSWER1_CA: 'faq_answer_erc_contact_Canada',
        FAQ_ERC_ANSWER1_CA_CONTACT: 'faq_answer_erc_contact_CanadaContact',
        FAQ_ERC_ANSWER1_COSTA_RICA: 'faq_answer_erc_contact_CostaRica',
        FAQ_ERC_ANSWER1_COSTA_RICA_CONTACT: 'faq_answer_erc_contact_CostaRicaContact',
        FAQ_ERC_ANSWER1_CZECH: 'faq_answer_erc_contact_CzechRepublic',
        FAQ_ERC_ANSWER1_CZECH_CONTACT: 'faq_answer_erc_contact_CzechRepublicContact',
        FAQ_ERC_ANSWER1_IRELAND: 'faq_answer_erc_contact_Ireland',
        FAQ_ERC_ANSWER1_IRELAND_CONTACT: 'faq_answer_erc_contact_IrelandContact',
        FAQ_ERC_ANSWER1_POLAND: 'faq_answer_erc_contact_Poland',
        FAQ_ERC_ANSWER1_POLAND_CONTACT: 'faq_answer_erc_contact_PolandContact',
        FAQ_ERC_ANSWER1_SOUTH_AFRICA: 'faq_answer_erc_contact_SouthAfrica',
        FAQ_ERC_ANSWER1_SOUTH_AFRICA_CONTACT: 'faq_answer_erc_contact_SouthAfricaContact',
        FAQ_ERC_ANSWER1_INDIA: 'faq_answer_erc_contact_India',
        FAQ_ERC_ANSWER1_INDIA_CONTACT: 'faq_answer_erc_contact_IndiaContact',
        FAQ_ERC_ANSWER1_GERMANY: 'faq_answer_erc_contact_Germany',
        FAQ_ERC_ANSWER1_GERMANY_CONTACT: 'faq_answer_erc_contact_GermanyContact',
        FAQ_ERC_ANSWER1_FRANCE: 'faq_answer_erc_contact_France',
        FAQ_ERC_ANSWER1_FRANCE_CONTACT: 'faq_answer_erc_contact_FranceContact',
        FAQ_ERC_ANSWER1_ITALY: 'faq_answer_erc_contact_Italy',
        FAQ_ERC_ANSWER1_ITALY_CONTACT: 'faq_answer_erc_contact_ItalyContact',
        FAQ_ERC_ANSWER1_SLOVAKIA: 'faq_answer_erc_contact_Slovak',
        FAQ_ERC_ANSWER1_SLOVAKIA_CONTACT: 'faq_answer_erc_contact_SlovakContact',
        FAQ_ERC_ANSWER1_SPAIN: 'faq_answer_erc_contact_Spain',
        FAQ_ERC_ANSWER1_SPAIN_CONTACT: 'faq_answer_erc_contact_SpainContact',
        FAQ_ERC_ANSWER1_AUSTRALIA: 'faq_answer_erc_contact_Australia',
        FAQ_ERC_ANSWER1_AUSTRALIA_CONTACT: 'faq_answer_erc_contact_AustraliaContact',
        FAQ_ERC_ANSWER1_BAHRAIN: 'faq_answer_erc_contact_Bahrain',
        FAQ_ERC_ANSWER1_BAHRAIN_CONTACT: 'faq_answer_erc_contact_BahrainContact',
        FAQ_ERC_ANSWER1_BELGIUM: 'faq_answer_erc_contact_Belgium',
        FAQ_ERC_ANSWER1_BELGIUM_CONTACT: 'faq_answer_erc_contact_BelgiumContact',
        FAQ_ERC_ANSWER1_CHINA: 'faq_answer_erc_contact_China',
        FAQ_ERC_ANSWER1_CHINA_CONTACT: 'faq_answer_erc_contact_ChinaContact',
        FAQ_ERC_ANSWER1_EGYPT: 'faq_answer_erc_contact_Egypt',
        FAQ_ERC_ANSWER1_EGYPT_CONTACT: 'faq_answer_erc_contact_EgyptContact',
        FAQ_ERC_ANSWER1_FINLAND: 'faq_answer_erc_contact_Finland',
        FAQ_ERC_ANSWER1_FINLAND_CONTACT: 'faq_answer_erc_contact_FinlandContact',
        FAQ_ERC_ANSWER1_JAPAN: 'faq_answer_erc_contact_Japan',
        FAQ_ERC_ANSWER1_JAPAN_CONTACT: 'faq_answer_erc_contact_JapanContact',
        FAQ_ERC_ANSWER1_MEXICO: 'faq_answer_erc_contact_Mexico',
        FAQ_ERC_ANSWER1_MEXICO_CONTACT: 'faq_answer_erc_contact_MexicoContact',
        FAQ_ERC_ANSWER1_MOROCCO: 'faq_answer_erc_contact_Morocco',
        FAQ_ERC_ANSWER1_MOROCCO_CONTACT: 'faq_answer_erc_contact_MoroccoContact',
        FAQ_ERC_ANSWER1_NETHERLANDS: 'faq_answer_erc_contact_Netherlands',
        FAQ_ERC_ANSWER1_NETHERLANDS_CONTACT: 'faq_answer_erc_contact_NetherlandsContact',
        FAQ_ERC_ANSWER1_NEW_ZEALAND: 'faq_answer_erc_contact_NewZealand',
        FAQ_ERC_ANSWER1_NEW_ZEALAND_CONTACT: 'faq_answer_erc_contact_NewZealandContact',
        FAQ_ERC_ANSWER1_PHILIPPINES: 'faq_answer_erc_contact_Philippines',
        FAQ_ERC_ANSWER1_PHILIPPINES_CONTACT: 'faq_answer_erc_contact_PhilippinesContact',
        FAQ_ERC_ANSWER1_ROMANIA: 'faq_answer_erc_contact_Romania',
        FAQ_ERC_ANSWER1_ROMANIA_CONTACT: 'faq_answer_erc_contact_RomaniaContact',
        FAQ_ERC_ANSWER1_SINGAPORE: 'faq_answer_erc_contact_Singapore',
        FAQ_ERC_ANSWER1_SINGAPORE_CONTACT: 'faq_answer_erc_contact_SingaporeContact',
        FAQ_ERC_ANSWER1_SWEDEN: 'faq_answer_erc_contact_Sweden',
        FAQ_ERC_ANSWER1_SWEDEN_CONTACT: 'faq_answer_erc_contact_SwedenContact',
        FAQ_ERC_ANSWER1_SWITZERLAND: 'faq_answer_erc_contact_Switzerland',
        FAQ_ERC_ANSWER1_SWITZERLAND_CONTACT: 'faq_answer_erc_contact_SwitzerlandContact',
        FAQ_ERC_ANSWER1_UNITED_EMIRATES: 'faq_answer_erc_contact_UnitedArabEmirates',
        FAQ_ERC_ANSWER1_UNITED_EMIRATES_CONTACT: 'faq_answer_erc_contact_UnitedArabEmiratesContact',

    }

    public static readonly UI : UIType = {
       IMAGE: {
           ATOZ_LOGO_ALT: 'logo_alt'
       },
       ALUMNI: {
           WELCOME_HEADER: 'alumni_updated_welcome_heading',
           WELCOME_DESCRIPTION: 'alumni_updated_welcome_description',
           SETUP_STEP1: 'alumni_updated_setup_step_1',
           SETUP_STEP2: 'alumni_updated_setup_step_2',
           SETUP_STEP3: 'alumni_updated_setup_step_3',
           SETUP_ALTERNATE_CONTACT: 'alumni_setup_alternate_contact',
           CONTACT_INFO_HEADER: 'alumni_contact_info_heading',
           CONTACT_INFO_EMAIL_HEADER: 'alumni_contact_email_heading',
           CONTACT_INFO_EMAIL_ALTERNATE: 'alumni_contact_email_alternate',
           CONTACT_INFO_PHONE_HEADER: 'alumni_contact_phone_heading',
           CONTACT_INFO_PHONE_ALTERNATE: 'alumni_contact_phone_alternate',
           HELP_HEADER: 'alumni_help_topHeading',
           HELP_DESCRIPTION: 'alumni_help_description',
           GENERIC_ERROR_MESSAGE: 'generic_error',
           GENERIC_ERROR_LINK: 'generic_error_cta',
           VERIFY_ACCOUNT_HEADER: 'alumni_verify_account_request_heading',
           VERIFY_ACCOUNT_DESCRIPTION: 'alumni_verify_account_request_description',
           VERIFY_ACCOUNT_HELP_MESSAGE: 'alumni_verify_account_request_description2',
           CREATE_ACCOUNT_HEADER: 'alumni_create_account_screen_heading',
           CREATE_ACCOUNT_TEXTBOX_HEADER: 'alumni_create_account_screen_textbox_heading',
           CREATE_ACCOUNT_ERROR_MESSAGE: 'alumni_create_account_screen_error_message',
           RESEND_EMAIL_HEADER: 'alumni_resend_email_heading',
           RESEND_EMAIL_DESCRIPTION: 'alumni_resend_email_description',
           INVALID_LINK_HEADER: 'alumni_invalid_link_screen_header',
           INVALID_LINK_DESCRIPTION: 'alumni_invalid_link_screen_description',
           UPDATE_APP_MESSAGE: 'alumni_update_app_message',
           LANGUAGE_FOOTER: 'alumni_footer_language',
           HELP_FOOTER: 'alumni_footer_help_link',
           RESTART_SIGNUP_PROCESS_HEADER: 'alumni_restart_signup_header',
           RESTART_SIGNUP_PROCESS_DESCRIPTION: 'alumni_restart_signup_description'
           
       },
       BUTTONS: {
           BACK: 'back_btn',
           CLOSE: 'close_btn',
           SAVE: 'save_btn',
           SUBMIT: 'submit_btn',
           CONTACT_US: 'contact_us',
           START: 'start_btn',
           NOT_SURE: 'not_sure',
           FORGOT_ALIAS: 'forgot_alias_link',
           LOGIN: 'log_in',
           RESEND_EMAIL: 'alumni_resend_email_link',
           CONTINUE: 'alumni_create_account_screen_continue_button',
           RESEND_LINK: 'alumni_invalid_link_screen_resend_button'
       },
       USERTYPES: {
           OTHER_USERTYPE_BUTTON_TITLE: 'usertype_other_usertype_description_phase1_and_2_header',
           OTHER_USERTYPE_HEADER_PHASE1: 'usertype_other_usertype_description_phase1_web',
           OTHER_USERTYPE_HEADER_PHASE2: 'usertype_other_usertype_description_phase2_web2',
           OTHER_USERTYPE_HEADER: 'usertype_other_usertype_description_phase3_web2',
           OTHER_USERTYPE_HEADER_DA_CSGO: 'usertype_other_usertype_description_web_da_csgo',
           OTHER_USERTYPE_HEADER_DA_CSGO_SRU: 'usertype_other_usertype_description_web_da_csgo_sru',
           OTHER_USERTYPE_HEADER_DA_CSGO_SRU_PB: 'usertype_other_usertype_description_web_da_csgo_sru_pb',
           USERTYPES_SELECTION_TITLE: 'usertype_selection_title',
           CORPORATE_HEADER: 'usertype_corporate_header',
           CORPORATE_HEADER2: 'usertype_corporate_header2',
           CORPORATE_DESCRIPTION: 'usertype_corporate_description',
           DA_HEADER: 'usertype_da_header',
           DA_HEADER2: 'usertype_da_header2',
           DA_DESCRIPTION: 'usertype_da_description',
           DSP_HEADER: 'usertype_dsp_header2',
           DSP_DESCRIPTION: 'usertype_dsp_description2',
           CSGO_HEADER: 'usertype_csgo_header3',
           CSGO_HEADER2: 'usertype_csgo_header4',
           CSGO_DESCRIPTION: 'usertype_csgo_description3',
           SRU_HEADER: 'usertype_sru_header3',
           SRU_HEADER2: 'usertype_sru_header4',
           SRU_DESCRIPTION: 'usertype_sru_description3',
           ALUMNI_HEADER: 'usertype_alumni_header',
           ALUMNI_HEADER2: 'usertype_alumni_header2',
           ALUMNI_DESCRIPTION: 'usertype_alumni_description',
           PREBOARDER_HEADER: 'usertype_preboarder_header2',
           PREBOARDER_HEADER2: 'usertype_preboarder_header3',
           PREBOARDER_DESCRIPTION: 'usertype_preboarder_description2',
           JOBSEEKER_HEADER: 'usertype_jobseeker_header',
           JOBSEEKER_DESCRIPTION: 'usertype_jobseeker_description',
           PROSPECT_HEADER: 'usertype_prospect_header',
           PROSPECT_DESCRIPTION: 'usertype_prospect_description'
       },
       AEA: {
            AEA_INSTALL_HEADER: 'aea_install_header',
            AEA_INSTALL_DESCRIPTION: 'aea_install_description',
            AEA_INSTALL_REQUIREMENT1: 'aea_install_requirement1',
            AEA_INSTALL_REQUIREMENT2: 'aea_install_requirement2',
            AEA_INSTALL_REQUIREMENT3: 'aea_install_requirement3',
            AEA_INSTALL_REQUIREMENT4: 'aea_install_requirement4',
            AEA_INSTALL_LINK_HEADER: 'aea_install_link_header',
            AEA_INSTALL_LINK_URL: 'aea_install_link_url'
        },
        LOGIN_FORMS: {
            LOGIN_HEADER: 'get_started_with_atoz',
            LOGIN_HEADER2: 'log_in2',
            ASSOCIATE_LOGIN_TEXT: 'associate_login_text',
            ASSOCIATE_LOGIN_TEXT1: 'associate_login_text_a',
            ASSOCIATE_LOGIN_TEXT2: 'associate_login_text_b',
            ASSOCIATE_LOGIN_INPUT_HINT: 'associate_login_input_hint',
            LOGIN_LABEL: 'amazon_login2',
            LOGIN_SEPARATOR: 'subtle_picker_or',
            REQUIRED_FIELD_ERROR_MESSAGE: 'required_field_error_message'
        },
        CSGO: {
            CSGO_REGION_SELECTION_TITLE: 'csgo_region_selection_title',
            CSGO_REGION_NORTH_AMERICA: 'csgo_region_north_america',
            CSGO_REGION_EUROPE: 'csgo_region_europe',
            CSGO_REGION_APAC: 'csgo_region_apac'
        }
    }
    public static readonly FOOTER = {
        LANGUAGE: {
            CHANGE_LANGUAGE: 'change_language',
            CHOOSE_LANGUAGE: 'choose_language',
            CHANGE_LANGUAGE_HEADER: 'change_language_header'
        },
        CONTACT_US: 'contact_us',
        PRIVACY_POLICY: 'privacy_policy',
        NEED_HELP: 'need_help',
        GET_HELP: 'get_help'
    };
}
