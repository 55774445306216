export enum UserType {
    ALUMNI = "alumni",
    CORP = "corp",
    CSGO = "csgo",
    DA = "da",
    DSP = "dsp",
    PREBOARDER = "preboarder",
    JOBSEEKER = "jobseeker",
    SRU = "sru",
    OTHER = "other"
}